<template>
  <div class="form--group" :class="{ 'has-error': validation.hasError('locationText') }">
    <div class="left--col">
      <label for="location-text">
        <span>{{ $t('home.managerSection.form.step2.q1') }}</span>
      </label>
    </div>
    <div class="right--col">
      <client-only>
        <input
          ref="searchPlacesField"
          type="text"
          v-model="locationText"
          :placeholder="$t('home.managerSection.placeholder.location')"
          class="basic--input"
          id="location-text"
          name="location-text"
        />
        <span class="val-error" v-if="validation.hasError('locationText')">{{
          validation.firstError('locationText')
        }}</span>
        <div class="wrapper--suggest" v-show="autocompleteSuggestions.length > 0">
          <place-suggestion
            v-for="suggestion in autocompleteSuggestions"
            :key="suggestion.id"
            :suggestion="suggestion"
            @suggestionClicked="suggestionClicked"
            :hideSuggestion="hideSuggestion"
          />
        </div>
      </client-only>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import LodashMixin from '@/mixins/lodash';

const PlaceSuggestion = () => import('./place-suggestion');
import { Validator } from 'simple-vue-validator';

export default {
  mixins: [LodashMixin],
  components: {
    PlaceSuggestion,
  },
  data() {
    return {
      hideSuggestion: false,
      autocompleteService: null,
      sessionToken: null,
      autocompleteSuggestions: [],
      shouldSearch: true,
    };
  },
  created() {},
  computed: {
    ...mapGetters({}),
    locationText: {
      get() {
        return this.$store.state.v2.spaceManagerRequest.location;
      },
      set(value) {
        this.$store.commit('v2/spaceManagerRequest/SET_LOCATION', value);
      },
    },
  },
  watch: {
    locationText() {
      if (this.shouldSearch) {
        this.debounceGetSuggestion();
      } else {
        this.shouldSearch = true;
      }
    },
  },
  mounted() {
    // eslint-disable-next-line
    this.sessionToken = new google.maps.places.AutocompleteSessionToken();
    // eslint-disable-next-line
    this.autocompleteService = new google.maps.places.AutocompleteService();
    // eslint-disable-next-line
    this.debounceGetSuggestion = this.debounce(this.getSuggestion, 500);
    window.addEventListener('click', this.windowClickListener);
  },
  beforeDestroy() {
    window.removeEventListener('click', this.windowClickListener);
  },
  validators: {
    locationText(value) {
      return Validator.value(value).maxLength(
        150,
        this.$i18n.t('errors.location.max', { maxChar: 150 }),
      );
    },
  },
  methods: {
    validate: function () {
      return this.$validate().then(
        function (success) {
          return !!success;
        }.bind(this),
      );
    },
    // eslint-disable-next-line no-unused-vars
    windowClickListener(e) {
      if (!document.querySelector('.wrapper--suggest').contains(e.target)) {
        if (!this.$refs.searchPlacesField.contains(e.target)) {
          this.hideSuggestion = true;
        } else {
          this.hideSuggestion = false;
        }
      }
    },
    suggestionClicked(suggestion) {
      this.autocompleteSuggestions = [];
      this.shouldSearch = false;
      this.locationText = suggestion.description;
    },
    getSuggestion() {
      if (this.locationText) {
        this.autocompleteService.getPlacePredictions(
          {
            input: this.locationText,
            sessionToken: this.sessionToken,
            componentRestrictions: { country: 'id' },
          },
          this.displaySuggestions,
        );
      } else {
        this.autocompleteSuggestions = [];
      }
    },
    displaySuggestions(predictions, status) {
      if (status != window.google.maps.places.PlacesServiceStatus.OK) {
        this.autocompleteSuggestions = [];
        return;
      }
      this.autocompleteSuggestions = predictions;
    },
  },
};
</script>
